import "./App.css";
import settings from "./settings.js";
import telegram from "./assets/main1.png";
import main2 from "./assets/main2.png";
import main3 from "./assets/main3.png";
import twitter from "./assets/main4.png";
import main5 from "./assets/main5.png";
import main6 from "./assets/main6.png";
import main7 from "./assets/main7.png";
import main8 from "./assets/main8.png";
import main9 from "./assets/main9.png";
import main10 from "./assets/main10.png";
import dextools from "./assets/main11.png";
import main12 from "./assets/main12.png";
import main13 from "./assets/main13.png";
import logo from "./assets/logo.png";
import layout from "./assets/logo-layout.png";
import stencil from "./assets/stencil.png";

function App() {
	return (
		<div className="App">
			{/* <img src={stencil} className="Stencil" alt="" /> */}
			<div className="Main">
				<a href={settings.telegram} target="_blank" rel="noreferrer">
					<img src={telegram} className="telegram link hoverable" alt="" />
				</a>
				<img src={main2} className="main-2 hoverable" alt="" />
				<img src={main3} className="main-3 hoverable" alt="" />
				<a href={settings.twitter} target="_blank" rel="noreferrer">
					<img src={twitter} className="twitter link hoverable" alt="" />
				</a>
				<img src={main5} className="main-5 hoverable" alt="" />
				<img src={main6} className="main-6 hoverable" alt="" />
				<img src={main7} className="main-7 hoverable" alt="" />
				<img src={main8} className="main-8 hoverable" alt="" />
				<img src={main9} className="main-9 hoverable" alt="" />
				<img src={main10} className="main-10 hoverable" alt="" />
				<a href={settings.dextools} target="_blank" rel="noreferrer">
					<img src={dextools} className="dextools link hoverable" alt="" />
				</a>
				<img src={main12} className="main-12 hoverable" alt="" />
				<img src={main13} className="main-13 hoverable" alt="" />
				<img src={logo} className="logo" alt="" />
				<img src={layout} className="logo-layout" alt="" />
			</div>
		</div>
	);
}

export default App;
